<template>
    <ValidationObserver ref="validator" slim>
        <ValidationProvider v-slot="{ errors }" rules="required|max:4">
            <div class="confirmation">
                <v-text-field
                    v-model="code"
                    label="Введите код из смс"
                    counter="4"
                    outlined
                    :error-messages="errors[0]"
                    @keyup.enter="confirm"
                />
                <v-btn 
                    color="primary"
                    @click="confirm">Подтвердить</v-btn>
            </div>
        </ValidationProvider>
    </ValidationObserver>
</template>

<script>
    import store from '@/store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';

    export default {
        name: 'Confirmation',
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        data: () => ({
            code: '',
        }),
        computed: {
            ...mapState('auth', ['user']),
        },
        methods: {
            async confirm() {
                const valid = await this.$refs['validator'].validate();
                if(valid) {
                    await store.dispatch('auth/confirm', { code: this.code });
                    await store.dispatch('auth/fetch');
                    if(this.user.id) {
                        this.$router.push({ name: 'home' });
                    } else {
                        await store.dispatch('auth/logout');
                        this.$router.push({ name: 'user-authorization' });
                    }
                }
            },
            
        }
    }
</script>


<style lang="scss">
.confirmation {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 30%;
    left: 50%;
    margin: 0 0 0 -200px;
}
</style>
